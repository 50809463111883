import React from 'react';
import styled from 'styled-components';

import TestemonialBox from 'components/modules/testimonial';
import RecentPostBox from 'components/modules/recentPostBox';

const StyledSection = styled.section`
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
  }

  hr {
    background-color: ${({ theme }) => theme.color.active};
    width: 10rem;
    margin: 2rem auto;
  }

  padding: 10rem 0 0 0;
`;

const StyledHeading = styled.h3`
  margin: -5rem 0 5rem;
`;

const BlogPreview = () => {
  return (
    <StyledSection>
      <TestemonialBox />
      <StyledHeading>Najnowsze wpisy</StyledHeading>
      <RecentPostBox />
    </StyledSection>
  );
};

export default BlogPreview;
