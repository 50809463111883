import React from 'react';

import Layout from '../components/utils/layout';
import SEO from 'components/utils/seo';
import GridVeiw from 'components/utils/gridView';
import Hero from 'components/organisms/hero';
import MainSection from 'components/organisms/mainSection';
import BlogPreview from 'components/organisms/blogPreview';

const IndexPage = () => (
  <Layout>
    <SEO title='Główna' image='logo' />
    <GridVeiw>
      <Hero />
      <MainSection />
      <BlogPreview />
    </GridVeiw>
  </Layout>
);

export default IndexPage;
