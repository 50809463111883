import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import HeroImg from 'images/svg/hero.svg';
import Button from 'components/atoms/button';
import HighlightedText from 'components/atoms/highlightText';

const HeroSection = styled.section`
  display: flex;
  padding-bottom: 20rem;

  & > * {
    align-self: flex-start;
  }
  ${({ theme: { mq } }) => mq.desktop} {
    padding-bottom: 0rem;
    margin-top: -10rem;
  }
  ${({ theme: { mq } }) => mq.tablet} {
    flex-direction: column-reverse;
  }
`;

const SvgWrapper = styled.div`
  flex-basis: 50%;
  svg {
    width: 100%;
    justify-content: flex-end;
    align-self: flex-start;
  }
  ${({ theme: { mq } }) => mq.mediumDesktop} {
    margin-right: 5rem;
  }
  ${({ theme: { mq } }) => mq.desktop} {
    margin-left: 2.5rem;
  }
  ${({ theme: { mq } }) => mq.tablet} {
    width: 40rem;
    align-self: center;
    margin-top: -25rem;
    margin-bottom: -65rem;
  }
`;

const ContentBox = styled.div`
  flex-basis: 50%;
  display: flex;
  margin-top: 14rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 3rem;

  p {
    font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
  }

  h2 {
    text-align: left;
  }

  ${({ theme: { mq } }) => mq.mediumDesktop} {
    margin-left: 5rem;
  }
  ${({ theme: { mq } }) => mq.desktop} {
    margin-right: 2.5rem;
    margin-top: 20rem;
  }
  ${({ theme: { mq } }) => mq.bigTablet} {
    margin-right: 2.5rem;
    margin-top: 25rem;
  }
  ${({ theme: { mq } }) => mq.tablet} {
    margin: 30rem 10rem 25rem;
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    margin: 25rem 5rem 25rem;
  }
`;

const Hero = () => {
  const wrapper = useRef(null);
  useEffect(() => {
    const [elements] = wrapper.current.children;
    const flower = elements.getElementById('flower');
    const hero = elements.getElementById('hero');
    const mirrorHero = elements.getElementById('mirror-hero');
    const mirror = elements.getElementById('mirror');
    const floor = elements.getElementById('floor');
    const background = elements.getElementById('background');

    gsap.set(
      [...background.children, flower, hero, mirrorHero, mirror, floor],
      {
        autoAlpha: 0,
      },
    );

    const tl = gsap.timeline();

    tl.to(floor, { duration: 0.5, autoAlpha: 0.1 })
      .fromTo(
        [mirror, flower],
        { y: '-=100', scale: 0.8 },
        { duration: 0.5, y: '+=100', scale: 1, autoAlpha: 1 },
      )
      .fromTo(hero, { x: '+=200' }, { duration: 0.5, x: '-=200', autoAlpha: 1 })
      .to(mirrorHero, { duration: 0.5, autoAlpha: 1 })
      .fromTo(
        background.children,
        { x: '-=20' },
        {
          duration: 2.5,
          x: '+=20',
          autoAlpha: 0.1,
          stagger: 0.1,
        },
        '-=3',
      );
  });

  return (
    <HeroSection>
      <ContentBox>
        <aside>
          <h2>
            Koncepcja Na <HighlightedText>Sukces</HighlightedText>
          </h2>
          <p>
            Zarabianie, rozwój, trening - czyli jak zostać człowiekiem renesansu
            mającym pełną kontrolę nad otoczającym go światem! Nie czekaj wbijaj
            na bloga i zacznij działać już dziś, a ja pomogę Ci odnaleźć Twoją
            Koncepcję na Sukces!
          </p>
        </aside>
        <Button to='/blog'>sprawdź!</Button>
      </ContentBox>
      <SvgWrapper ref={wrapper}>
        <HeroImg />
      </SvgWrapper>
    </HeroSection>
  );
};

export default Hero;
