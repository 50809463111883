import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useImg } from 'hooks/useImg';

const Testimonial = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 5rem 2rem;
  background-color: ${({ theme }) => theme.color.mainLighter};
  position: relative;
  top: -20rem;
  border-radius: 2rem;
  border: 2px solid ${({ theme }) => theme.color.devider};
  box-shadow: ${({ theme: { shadow } }) => shadow.borderShadow};

  ${({ theme: { mq } }) => mq.bigPhone} {
    width: 90%;
  }
`;

const StyledImg = styled(Img)`
  margin: 0 auto;
  position: relative;
  top: -13rem;
  width: 20rem;
  border-radius: 50%;
  border: 5px solid transparent;
  background-color: inherit;
  box-shadow: ${({ theme: { shadow } }) => shadow.borderShadowLight};
`;

const StyledContentBox = styled.div`
  width: 80%;
  margin: -10rem auto 0;
  text-align: center;

  h3 {
    font-size: 3rem;

    ${({ theme: { mq } }) => mq.tablet} {
      font-size: ${({ theme: { fontSize } }) => fontSize.m};
    }
  }
  p {
    font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
    text-align: justify;
  }
`;

const TestimonialBox = () => {
  const testimonialWrapper = useRef(null);
  const { authorPhoto } = useImg();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(testimonialWrapper.current, {
      y: '+=100',
      autoAlpha: 0,
      duration: 0.5,
      scrollTrigger: {
        trigger: testimonialWrapper.current,
        start: 'top bottom ',
        toggleActions: 'play none none none',
      },
    });
  });

  return (
    <Testimonial ref={testimonialWrapper}>
      <StyledImg fluid={authorPhoto}></StyledImg>
      <StyledContentBox>
        <h3>Kamil Zieliński</h3>
        <hr />
        <p>
          Sportowy świr, perfekcjonista, specjalista od mediów
          społecznościowych. Pasionat dążenia do wolności finansowej, nie
          zależnie od etatu. Od kilku lat aktywnie dzieli się swoją wiedzą na
          temat marketingu, treningu oraz stylu życia. DaVinci XXI wieku, swoje
          zainteresowania traktuje jak sztukę oraz rzemiosło, które
          nieprzerwanie rozwija.
        </p>
      </StyledContentBox>
    </Testimonial>
  );
};

export default TestimonialBox;
