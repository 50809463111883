import React from 'react';
import styled from 'styled-components';
import NewsIcon from 'images/svg/news.svg';
import Protection from 'images/svg/protection.svg';
import Friendly from 'images/svg/friendly.svg';

const StyledWrapper = styled.div.attrs(props => ({ className: 'feature' }))`
  margin-top: 0;
  padding: 5rem 3rem 2rem;
  background-color: ${({ theme }) => theme.color.light};
  min-height: 43rem;
  box-shadow: ${({ theme }) => theme.shadow.borderShadow};
  border-radius: 2rem;
  text-align: center;

  p {
    font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
    text-align: left;
  }

  svg {
    height: 10rem !important;
    width: 10rem;
    fill: ${({ theme }) => theme.color.main};
  }

  ${({ theme: { mq } }) => mq.desktop} {
    width: 60%;
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    width: 90%;
  }
`;

const StyledHeader = styled.h2`
  color: black;
  height: 5rem;
  font-size: 2.5rem;
  ${({ theme: { mq } }) => mq.tablet} {
    font-size: ${({ theme: { fontSize } }) => fontSize.m};
  }
`;

const StyledHr = styled.hr`
  opacity: 0.7;
`;

const Feature = ({ title, icon, children }) => {
  return (
    <StyledWrapper>
      <div>
        {icon === 'news' ? (
          <NewsIcon />
        ) : icon === 'protection' ? (
          <Protection />
        ) : (
          icon === 'friendly' && <Friendly />
        )}
      </div>
      <StyledHeader>{title}</StyledHeader>
      <StyledHr />
      <p>{children}</p>
    </StyledWrapper>
  );
};

export default Feature;
