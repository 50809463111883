import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import WideSection from 'components/atoms/wideSection';
import Feature from 'components/modules/feature';
import HighlightedText from 'components/atoms/highlightText';
import Logo from 'images/svg/logo-kns.svg';
import Button from 'components/atoms/button';

const FeatureBox = styled.div`
  width: 95%;
  padding: 10rem calc((100% - 128rem) / 2 - 8%) 10rem calc((100% - 128rem) / 2);
  position: relative;
  top: -25rem;
  background: ${({ theme }) => theme.color.light2};
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr) 1rem;
  grid-gap: 5rem;
  box-shadow: ${({ theme: { shadow } }) => shadow.borderShadow};

  ${({ theme: { mq } }) => mq.mediumDesktop} {
    padding: 10rem calc((100% - 128rem) / 2 - 8%) 10rem 5rem;
  }
  ${({ theme: { mq } }) => mq.desktop} {
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
  }
  ${({ theme: { mq } }) => mq.tablet} {
    margin-bottom: -38rem;
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    margin-bottom: -38rem;
    padding: 10rem 5rem 10rem 5rem;
  }
`;

const AboutBox = styled.div`
  display: flex;
  justify-content: space-evenly;

  width: 100%;
  margin: -20rem 0 10rem;
  padding: 0 calc((100% - 128rem) / 2);
  svg {
    width: 40rem;

    ${({ theme: { mq } }) => mq.tablet} {
      width: 50rem;
      margin-bottom: -5rem;
    }
    ${({ theme: { mq } }) => mq.phone} {
      width: 45rem;
    }
  }

  ${({ theme: { mq } }) => mq.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: -35rem 0 10rem;
  }
`;

const AboutContent = styled.div.attrs(props => ({
  className: 'about-content-prev',
}))`
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
  }

  ${({ theme: { mq } }) => mq.tablet} {
    margin: -10rem 10rem 5rem;
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    margin: -10rem 5rem 5rem;
  }
`;

const MainSection = () => {
  const featureWrapper = useRef(null);
  const aboutWrapper = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const featureBoxes = gsap.utils.toArray(
      featureWrapper.current.querySelectorAll('.feature'),
    );

    const aboutLogo = aboutWrapper.current.querySelector('svg');
    const aboutContent = aboutWrapper.current.querySelector(
      '.about-content-prev',
    );

    // Features appears on scroll
    featureBoxes.forEach(box => {
      gsap.from(box, {
        x: '-=100',
        autoAlpha: 0,
        duration: 0.75,
        scrollTrigger: {
          trigger: box,
          start: 'top 500px',
          end: 'bottom bottom',
          toggleActions: 'play none none none',
        },
      });
    });

    // About preview animation
    const tlAbout = gsap.timeline({
      scrollTrigger: {
        trigger: aboutWrapper.current,
        start: 'top 500px',
        end: 'bottom bottom',
        toggleActions: 'play none none none',
      },
    });
    tlAbout
      .from(aboutLogo, { x: '-=100', autoAlpha: 0, duration: 0.75 })
      .from(
        aboutContent,
        { x: '+=100', autoAlpha: 0, duration: 0.75 },
        '-=0.75',
      );
  });

  return (
    <WideSection bgc={({ theme }) => theme.color.light}>
      <FeatureBox ref={featureWrapper}>
        <Feature title='Pewne info' icon='news'>
          Tylko sprawdzone informacje, jeżeli popieram jakiś projekt to znaczy,
          że wierzę w niego z całego serca! Jeżeli piszę o biznesie to znaczy,
          że już w nim inwestuje, jeżeli o treningu, to już go przerobiłem!
        </Feature>
        <Feature title='ANTY SCAM' icon='protection'>
          Mnie nie można kupić! Oszustom mówię stanowcze nie! Zawsze na bieżąco
          informuję o ryzyku, fake newsach, i dziwnych sytuacjach w mojej
          opinii. Będę waszym prywatnym firewallem, wyczulonym na wszelkie
          niepożądane działania!
        </Feature>
        <Feature title='Przyjazna atmosfera' icon='friendly'>
          Na grupach i blogu jedną z najważniejszych zasad jest szacunek do
          drugiego człowieka. Zachowania pozbawione kultury lub atakujące inne
          osoby nie będą tolerowane. Merytorycznie na 100%!
        </Feature>
      </FeatureBox>
      <AboutBox ref={aboutWrapper}>
        <Logo />
        <AboutContent>
          <h2>
            Czym jest KN<HighlightedText>S</HighlightedText>?
          </h2>
          <p>
            Zbiór opinii poprzez własne doświadczenia analizując wszystko za i
            przeciw wskazując Koncepcję, dzięki której możesz odnieść Sukces.
            Budowanie własnego Brandu, Portfela i Formy.
          </p>
          <Button to='/o-mnie' color='dark'>
            Czytaj dalej!
          </Button>
        </AboutContent>
      </AboutBox>
    </WideSection>
  );
};

export default MainSection;
